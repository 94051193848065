.our-image {
  max-height: 100vw;
}
@media screen and (min-width: 768px) {
  .our-image {
    max-height: 406px;
  }
}
.program-image {
}

.html-content p {
  @apply mb-2;
}

.react-tabs__tab--selected {
  /* @apply border-0 !important; */
  @apply font-bold;
}
.react-tabs__tab-panel--selected {
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  margin-top: -10px;
  margin-bottom: 25px;
}
